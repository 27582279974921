(function ($) {
    $('.project__image').first().addClass('is--active');
    $('.project__title').first().addClass('is--active');

    $(document).on('mouseover', '.js-toggle-button', function (e) {
        e.preventDefault();
        var id = $(this).data('id');
        if ($('.project__title').hasClass('is--active')) {
            $('.project__title').removeClass('is--active');
        }

        $(this).toggleClass('is--active');

        // Show content
        if ($('.project__image').hasClass('is--active')) {
            $('.project__image').removeClass('is--active');
        }

        $('.project__image[data-id="' + id + '"]').addClass('is--active');
    });

    $('.js-show-all').parent().addClass('is--show');

    $('.js-filter-data').on('click', function (e) {
        e.preventDefault();
        var slugId = $(this).parent().data('slug');

        if ($('.js-filter-data').parent().hasClass('is--show')) {
            $('.js-filter-data').parent().removeClass('is--show');
        }

        $(this).parent().addClass('is--show');

        $('.project').addClass('is--hide');

        // $('.project').addClass('is--hide');
        if ($('.project[data-slug="' + slugId + '"]')) {
            $('.project[data-slug="' + slugId + '"]').removeClass('is--hide');
        }
    });

    $('.js-show-all').on('click', function () {
        $('.project').removeClass('is--hide');
    });
})(jQuery);
