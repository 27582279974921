(function ($) {
	// Set action to menu button
	$('.js-toggle-nav').on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('is--active');
		$('.site-header').toggleClass('site-header--show-overflow');
		$(this).parent().find('.mobilenav').toggleClass('is--active');

		if ($('.navigation').hasClass('is--active')) {
			var topScrollPos = $(window).scrollTop();
			$('body').addClass('no-scroll').attr('data-scrollpos', topScrollPos);
		} else {
			$('body').removeClass('no-scroll');
			$(window).scrollTop($('body').attr('data-scrollpos'));
			$('body').attr('data-scrollpos', '');
		}
	});

	$('.js-toggle-subnav').on('click', function (e) {
		e.preventDefault();
		$(this).parents('li').toggleClass('is--active');
	});

	$(window).on('scroll', function () {
		var scrollPos = $(window).scrollTop();
		var headerHeight = $('.site-header').height();

		if (scrollPos > headerHeight) {
			$('.site-header').addClass('site-header--sticky');
		} else {
			$('.site-header').removeClass('site-header--sticky');
		}
	});

})(jQuery);
